<template>
  <div>
    <div class="content__party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
                    :options="limitOptions"
                    :selected="limitSelected"
                    :closeOnOutsideClick="true"
                    @updateOption="updateLimiter"
          >
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из
          <span>{{ pagination.count }}</span>
        </div>
      </div>
    </div>
    <v-client-table :data="table.logs" :columns="table.columns" :options="table.options" ref="discountTable"
                    class="table-default">
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns';

export default {
  name: 'Discount',
  components: {dropdown},
  data() {
    return {
      table: {
        logs: [],
        columns: ['id', 'number', 'created_at', 'comment'],
        options: {
          headings: {
            id: 'ID',
            number: 'Номер карты',
            created_at: 'Дата создания',
            comment: 'Комментарий'
          },
          pagination: {show: false},
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback() {
            return 'table-default__row';
          },
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
      limitSelected: {name: this.$store.state.admin.company.discountLimit},
      limitOptions: [
        {name: 20},
        {name: 40},
        {name: 60},
        {name: 80},
        {name: 100},
      ],
    };
  },
  mounted() {
    this.limitSelected.name = 20;
    this.fetchLogs(1, this.$store.state.admin.company.discountLimit);
  },
  beforeDestroy() {
    this.updateLimiter({name: 20});
  },
  methods: {
    fetchLogs(page = 1, limit) {
      this.$store.dispatch('admin/company/GET_DISCOUNT_LOG', {id: this.$route.params.id, page, limit})
        .then(response => {
          if (response.status >= 400) {
            this.$notify({
              type: 'warn',
              title: 'Внимание!',
              text: 'Ошибка получения данных'
            });
          }
          this.table.logs = response.data.results;
          this.pagination.pages = response.data.pages;
          this.pagination.count = response.data.count;
        })
        .catch(response => {
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
    updateLimiter(data) {
      this.$store.commit('admin/company/changeDiscountLimit', data.name);
      this.$refs.discountTable.setLimit(this.$store.state.admin.company.discountLimit);
      this.fetchLogs(1, this.$store.state.admin.company.discountLimit);
    },
    clickPaginationCallback(page) {
      this.fetchLogs(page, this.$store.state.admin.company.discountLimit);
    },
    returnStart() {
      return (this.pagination.page * this.table.logs.length) - (this.table.logs.length - 1);
    },
    returnEnd() {
      return this.returnStart() + this.table.logs.length - 1;
    },
  }
};
</script>